<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">Loan History</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :data="loans" :columns="columns" :loading="loading" :query="searchQuery" ref="table" />
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				searchQuery: '',
				company: null,
				loading: false,
				loans: [],
				columns: [
					{
						name: 'type',
						th: 'Loan Type',
						render: (loan) => loan.loan_type
					},
					{
						name: 'loan_amount',
						th: 'Loan Amount',
					},
					{
						name: 'created_at',
						th: 'Loan Date',
					},
					{
						name: 'tenure',
						th: 'Tenure',
						render: () => 5
					},
					{
						name: 'repayed',
						th: 'Total Repayed',
						render: () => 0
					},
					{
						name: 'status',
						th: 'Status',
						render: (company, status) => {
							switch (status) {
								case 'approved':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
								case 'declined':
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
								case 'paid':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
								case 'pending':
								default:
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
							}
						}
					},
				]
			}
		},
		beforeMount() {
			this.getLoans();
		},
		methods: {
			async approve(loan) {
				this.approving = true;
				await this.$post({
					url: `${this.$baseurl}/staff/loans/approve`,
					data: {
						loan_id: loan.id
					},
					headers: this.headers,
					success: response => {
						this.loans = response.data.data;
					}
				});
				this.approving = false;
			},
			async getLoans() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies/staff/loans/${this.user.company_id}`,
					headers: this.headers,
					success: response => {
						this.loans = response.data.loans;
					}
				});
				this.loading = false;
			}
		}
	}
</script>